import React from 'react'
import './TheStory.scss'
import customTexture102 from '../Photos/102-01.svg'
import customTexture106 from '../Photos/106-01.svg'
import customTexture109 from '../Photos/109-01.svg'
import customTexture22 from '../Photos/22-01.svg'
import customTexture90 from '../Photos/90-01.svg'

class TheStory extends React.Component{

    render(){

        return(
            <div className="TheStory-container">
                <div className="TheStory-contents">

                <div className = "custom-texture-109">
                        <img src={customTexture109}/>
                    </div>
                    <div className = "custom-texture-22">
                        <img src={customTexture22}/>
                    </div>
                    <div className = "custom-texture-106">
                        <img src={customTexture106}/>
                    </div>
                    <div className = "custom-texture-90">
                        <img src={customTexture90}/>
                    </div>
                    <div className = "custom-texture-102">
                        <img src={customTexture102}/>
                    </div>
                    
                    <div className = "Section-container">
                        <div className = "Section-contents">
                            <h2 id = "title"> Recycling </h2>
                            <p id ="contents" > <span id="in-content">Design by Tunder</span> brings out the perspective of “timeless” with a big dash of creativity in recycling, turning every single shabby or worn-out item and fiber into an unexpected work of art, furthering its purpose. </p>
                        </div>
                    </div>
                   
                    <div className = "Section-container-right">
                        <div className = "Section-contents">
                            <h2 id = "title"> Sustainable </h2>
                            <p id ="contents"> As the consequences of mass-production are heating up, the concept of green sustainability is a real demand. DesignByTunder believes that reliable products begin with a deep care for the environment and people. Therefore, every single piece of crafting is ethically and sustainably created according to the brand’s standards. </p>
                        </div>
                    </div>
                    <div className = "Section-container">
                        <div className = "Section-contents">
                            <h2 id = "title"> On a budget </h2>
                            <p id ="contents"> By upholding the belief that everyone should enjoy the beauty and exclusiveness of handmade art, the brand DesignByTunder prides itself in offering a broad range of crafts that won’t break the bank, catering to all tastes and requirements.  </p>
                        </div>
                    </div>
                    <div className = "Section-container-right">
                        <div className = "Section-contents">
                            <h2 id = "title"> Custom </h2>
                            <p id ="contents"> DesignByTunder is a brand embodying self-expression and authenticity as every single product conveys a unique story and its corresponding feelings. Therefore, everything can and will be made in your beautiful image sheltering your stories and feelings. </p>
                        </div>
                    </div>
                    <div className = "Section-container">
                        <div className = "Section-contents">
                            <h2 id = "title"> Gifts </h2>
                            <p id ="contents"> “I love you”s, “happy birthday!”s or simply the “you are important” reminders to your dear ones are never to become mainstream and never to be better expressed than through an ireplicable gift conveying your ireplicable bond. This is what underlies the way your message will be brought to life through a whole range of DIY techniques.  </p>
                        </div>
                    </div>
                    <div className = "Section-container-right">
                        <div className = "Section-contents">
                            <h2 id = "title"> Handmade for mental clarity  </h2>
                            <p id ="contents"> There’s no time like “me-time” and crafting has surely become the new yoga session. Therefore, a wide and various range of DIY tutorials is available for you, each and every one of them aiming to become your so-much-needed break for mental clarity.  </p>
                        </div>
                    </div>

                    <div className = "Section-container-center">
                        <div className = "Section-contents">
                            <p id ="contents"> “Given that for me plain words have never quite properly fulfilled the right way to express my vision on this beautiful “thing” called “life”, I have to admit that being able to do it through the means of my craft has been the fuel which kept me going throughout the years and turned my passion into my personal brand.” 
 </p>
                            <p id="title"> Tunder </p>
                        </div>
                    </div>

                </div>
            </div>
        )
        
    }
}

export default TheStory;