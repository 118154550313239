import React from 'react'
import './HomePage-new.scss'
import thePortfolio from '../Photos/the-portfolio2.svg'
import theStory from '../Photos/the-story2.svg'
import theLearning from '../Photos/the-learning2.svg'
import theLogo from '../Photos/logo.svg'
import middleRectangle from '../Photos/middle-rectangle.svg'
import rectangleLeft from '../Photos/rectangle-left.svg'
import rectangleRight from '../Photos/rectangle-right.svg'
import textureLeft from '../Photos/texture-left.svg'
import textureRight from '../Photos/texture-right.svg'
import theShop from '../Photos/the-shop2.svg'
import verticalTexture1 from '../Photos/vertical-texture-1.svg'
import verticalTexture2 from '../Photos/vertical-texture-2.svg'

import customTexture102 from '../Photos/102-01.svg'
import customTexture106 from '../Photos/106-01.svg'
import customTexture109 from '../Photos/109-01.svg'
import customTexture22 from '../Photos/22-01.svg'
import customTexture90 from '../Photos/90-01.svg'


class HomePage extends React.Component{

    scrollHorizontally = e => {
        console.log(e);
        e = window.event || e;
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        var scrollSpeed = 60; // Janky jank <<<<<<<<<<<<<<
        // document.getElementsByClassName("HomePage-contents").scrollLeft
        document.documentElement.scrollLeft -= (delta * scrollSpeed);
        document.body.scrollLeft -= (delta * scrollSpeed);
        e.preventDefault();
      };

      componentDidMount() {
        // window.addEventListener('wheel', this.onWheel,{ passive: false });

        if (window.addEventListener) {
            console.log("asd")
            // IE9, Chrome, Safari, Opera
            window.addEventListener("mousewheel", this.scrollHorizontally, {passive: false});
            // Firefox
            window.addEventListener("DOMMouseScroll", this.scrollHorizontally, {passive: false});
          } else {
            // IE 6/7/8
            window.attachEvent("onmousewheel", this.scrollHorizontally, {passive: false});
          }
      }
    render(){

        return (
            <div className ="HomePage-container">
                <div className = "HomePage-contents">
                    <div className = "the-logo">
                        <img src={theLogo}/>
                    </div>
                    <div className = "the-portfolio">
                        <img src={thePortfolio}/>
                    </div>
                    <div className = "the-story">
                        <img src={theStory}/>
                    </div>
                    <div className = "the-learning">
                        <img src={theLearning}/>
                    </div>
                    <div className = "middle-rectangle">
                        <img src={middleRectangle}/>
                    </div>
                    <div className = "rectangle-left">
                        <img src={rectangleLeft}/>
                    </div>
                    <div className = "rectangle-right">
                        <img src={rectangleRight}/>
                    </div>
                    <div className = "texture-left">
                        <img src={textureLeft}/>
                    </div>
                    <div className = "texture-right">
                        <img src={textureRight}/>
                    </div>
                    <div className = "the-shop">
                        <img src={theShop}/>
                    </div>
                    <div className = "vertical-texture-1">
                        <img src={verticalTexture1}/>
                    </div>
                    <div className = "vertical-texture-2">
                        <img src={verticalTexture2}/>
                    </div>


                    <div className = "custom-texture-109">
                        <img src={customTexture109}/>
                    </div>
                    <div className = "custom-texture-22">
                        <img src={customTexture22}/>
                    </div>
                    <div className = "custom-texture-106">
                        <img src={customTexture106}/>
                    </div>
                    <div className = "custom-texture-90">
                        <img src={customTexture90}/>
                    </div>
                    <div className = "custom-texture-102">
                        <img src={customTexture102}/>
                    </div>

                   

                </div>
            </div>
        )
    }
}

export default HomePage;