import React from 'react';
import {
  BrowserRouter as Router,
  Route } from "react-router-dom";
import './App.scss';
import ComingSoon from './Pages/ComingSoon';
import HomePage from './Pages/HomePage-new';
import TheStory from './Pages/TheStory';

import 'rsuite/dist/styles/rsuite-default.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


class App extends React.Component {

  render(){
    return (
      <div className="App">
        <header className="App-header">
          <Router>
              <ToastContainer autoClose = {1500}/>
              <Route exact path = "/home" component = {HomePage} />
              <Route exact path = "/" component = {ComingSoon} />
              <Route exact path = "/theStory" component = {TheStory} />

            </Router>
        </header>
      </div>
    )
  }
 
}

export default App;
