import React from 'react'
import './ComingSoon.scss'
// import MyPicture from './mypng.png'
import { Input, InputGroup, Icon } from 'rsuite';
import { Button } from 'rsuite';
import { toast } from 'react-toastify';
import logo from './logo.svg'
import axios from 'axios'

class ComingSoon extends React.Component{

    state = {
        timeLeft: {},
        email : "",
        loading: false
    }

    calculateTimeLeft = () => {

        let year = new Date().getFullYear();
        const difference = +new Date(`08/01/${year}`) - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60)
            };
          }

          return timeLeft;
    }

    constructor() {
        super();
        this.setState({
            timeLeft: this.calculateTimeLeft()
        });
    }

    componentDidMount = () => {
        setInterval(() => {
            this.setState({
                timeLeft: this.calculateTimeLeft()
            });
          }, 1000);
    }

    componentWillUnmount = () => {

    }

     sendEmail = async () => {
        // eslint-disable-next-line
        var re = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
        let result = re.test(this.state.email);

        console.log(result)
        if ( result === true ){
            this.setState({
                loading: true
            })

            try {
                await axios.post("https://admin.designbytunder.com/newsletters/createNew", {
                    email: this.state.email,
                    date : new Date()
                })
                this.setState({
                    loading: false,
                    email: ""
                })
                toast.success("Thank you for registering!")
            }
            catch(e){
                toast.error("An error has occured")
            }
        }
        else{
            toast.error("The email is not correct")
            return;
        }

    }
    render(){
        return(
            <div className = "ComingSoon-container">
                <div className = "ComingSoon-contents">
                    <div className = "Left-section">
                        
                        <img className = "logo" alt ="" src={logo} />
                        <p className = "subtitle"> Official website launch </p>
                        <p className = "title" > COMING SOON </p>
                        <div className = "Countdown-section">
                            <div className = "Mini-Countdown">
                                <p> {this.state.timeLeft.days} </p>
                                <p className = "name"> days </p>
                            </div>
                            <div className = "Mini-Countdown">
                                <p> {this.state.timeLeft.minutes} </p>
                                <p className = "name"> minutes </p>
                            </div>
                            <div className = "Mini-Countdown">
                                <p> {this.state.timeLeft.seconds} </p>
                                <p className = "name"> seconds </p>
                            </div>
                        </div>
                        <div className = "subtitles">
                            <p className = "subtitle2"> <span id="bonus"> Bonus </span></p>
                            <p id = "highlight" className = "subtitle2">FREE "Reduce - Reuse - Recycle - Remember your summer" <br/> online tutorial</p>
                        </div>
                        <InputGroup>
                            <Input placeholder = "Your email address" onChange = {(value) => this.setState({ email : value })} value = {this.state.email}/>

                        </InputGroup>
                        <div className= "submit-button">
                            <Button size="lg" disabled = {this.state.loading} onClick = {this.sendEmail} appearance="primary"> Save my spot <Icon style = {{marginLeft: "0.5rem"}} icon="send" /></Button>
                        </div>
                        <div className= "social-media">
                            <a href = "https://www.facebook.com/designedbytunder"  rel="noreferrer" target="_blank"><Icon className = "icon" icon='facebook-official' /></a> 
                            <a href = "https://www.instagram.com/designbytunder/"  rel="noreferrer" target="_blank"><Icon className = "icon" icon='instagram' /></a>
                            <a href = "https://www.youtube.com/c/TunderMitreaCrafts/featured"  rel="noreferrer" target="_blank"><Icon className = "icon" icon='youtube-play' /></a>
                            <a href = "https://www.pinterest.co.uk/designbytunder/_saved/"  rel="noreferrer" target="_blank"><Icon className = "icon" icon='pinterest-square' /></a>

                        </div>
                    </div>
                    {/* <div className = "Right-section">
                         <img className = "image" src={logo} /> */}

                        {/* <img className = "image" src={MyPicture} /> */}
                    {/* </div> */}
                </div>
            </div>
        )
    }
}

export default ComingSoon;